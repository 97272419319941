import L from "leaflet";
import 'leaflet/dist/leaflet.css';
const initMaps = () => {
    // look for a document ID of #leafletMap
    const theMapEl = document.getElementById('leafletMap');

    // helper function to calculate the pixel distance between two points
    function calculateDistance(x1, y1, x2, y2) {
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        return distance;
    }

    if (theMapEl) {
        // define the map centre point based on user-given data attributes
        const mapLat = theMapEl.getAttribute('data-map-center-lat');
        const mapLng = theMapEl.getAttribute('data-map-center-lng');
        const mapZoom = theMapEl.getAttribute('data-map-zoom') ?? 15;
        // define the map layer bounds
        const baseMapLayerBounds = window.lanserCommunity.base.baseMapLayerBounds ?? [];
        const baseMapScaleFactor = window.lanserCommunity.base.imgScaleFactor ?? 1;
        const baseMapImageSrc = window.lanserCommunity.base.imgSrc ?? '';
        const baseMapWidth = Number(window.lanserCommunity.base.imgWidth) ?? 1;
        const baseMapHeight = Number(window.lanserCommunity.base.imgHeight) ?? 1;
        const baseMapOpacity = window.lanserCommunity.base.imgOpacity ?? 1;
        // TESTING: grab amenities from global window object
        const amenities = window.lanserCommunity.amenities ?? [];

        if (baseMapLayerBounds.length < 1) {
            console.error('Whoops! No map layer bounds provided in CMS!')
            return;
        }

        // define the default amenity icon:
        const amenityIcon = L.icon({
            iconUrl: window.lanserCommunity.amenityIcon,
            iconSize: [36, 72], // size of the icon
            iconAnchor: [18, 72], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -72], // point from which the popup should open relative to the iconAnchor
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
        });

        // define all of the final control layergroup objects in a handy object
        let mapLayerGroups = {
            stages: L.layerGroup(),
            amenities: L.layerGroup(),
        }

        // ====================

        // define a new map tile layer
        const tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20,
        })

        // ----- STAGES ----- //
        // TESTING: loop through window.lanserCommunity.stages and add their image to the map layers
        for(let i = 0; i < window.lanserCommunity.stages.length; i++) {
            
            // set the image url
            // const imageUrl = window.lanserCommunity.stages[i].imageUrl
            // define the image/svg overlay for the specific stage
            // const stage = L.imageOverlay(imageUrl, imgBounds, {
            //     opacity: 1,
            //     zIndex: 1 + i,
            // })

            // or... set an svgElement by retrieving the image from the DOM
            const svgElement = document.querySelector(`svg.stagemap_${i}`)
            const stage = L.svgOverlay(svgElement, imgBounds, {
                opacity: 1,
                zIndex: 1 + i,
            })

            // add a new stage layerGroup to the mapLayerGroups 'stages' object
            stage.addTo(mapLayerGroups.stages)
        }

        // ----- AMENITIES ----- //
        // TESTING: loop through the amenities and create a marker for each
        for(let i = 0; i < amenities.length; i++) {
            const amenity = amenities[i]

            const amenityMarker = L.marker([amenity.lat, amenity.lng], {
                icon: amenityIcon,
            }).bindPopup(amenity.title)

            // add a new amenity layerGroup to the mapLayerGroups 'amenities' object
            amenityMarker.addTo(mapLayerGroups.amenities)
        };

        // ----- MAP LAYER ----- //
        // create a new map object at the window level
        // NOTE: we hard-code the initial map creation zoom level, as it affects the imgBounds calc below.. 
        // then once we have added the layers, we re-set the zoom level as defined in the CMS-specified zoom attribute
        window.masterplanMap = L.map('leafletMap',{
            scrollWheelZoom: false,
            center: [mapLat, mapLng],
            zoom: 18,
            layers: [tileLayer, mapLayerGroups.stages],
        })

        // ----- BASE MAP IMAGE ----- //

        // If there are two latLngBounds provided in baseMapLayerBounds, we use both to calculate the bounding box of 
        // the map image, otherwise we use the first latLngBounds, the map width/height and the imgScaleFactor to calculate the bounding box of the map image
        let imgBounds = [];
        if (baseMapLayerBounds.length === 2) {
            // use both latLngBounds to calculate the bounding box of the map image
            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                baseMapLayerBounds[1],
            ])
        } else {
            // use the first latLngBounds anc calc the rest. We calculate the resulting lat/lng of the bottom corner using 
            // various leaflet methods to get the map container points and then convert back to latLng

            // the containerPoint of the top left and bottom right corners of the map image
            const originContainerPoint = masterplanMap.latLngToContainerPoint(baseMapLayerBounds[0], theMapEl)
            const destinationContainerPoint = L.point(
                originContainerPoint.x + (baseMapWidth * baseMapScaleFactor),
                originContainerPoint.y + (baseMapHeight * baseMapScaleFactor)
            )

            // the latLng of the bottom right corner of the map image
            const destinationLatLngCoords = masterplanMap.containerPointToLatLng(destinationContainerPoint)

            imgBounds = L.latLngBounds([
                baseMapLayerBounds[0],
                destinationLatLngCoords
            ])
        }

        // create the community base layer image
        const communityBaseLayer = L.imageOverlay(baseMapImageSrc, imgBounds, {
            opacity: baseMapOpacity,
            zIndex: 1,
        })

        // add the base layer to the map
        communityBaseLayer.addTo(window.masterplanMap)

        // now re-set the zoom level as defined in the data attribute
        window.masterplanMap.setZoom(mapZoom)

        // finally create a new Layers Control and add it to the map, 
        // with the mapLayerGroup stages as the default 'on'
        const layersControl = L.control.layers(null, {
            "Show Stages": mapLayerGroups.stages,
        }, {
            collapsed: false,
        }).addTo(masterplanMap)

        // afterwards, add the amenities layerGroup to the layersControl
        layersControl.addOverlay(mapLayerGroups.amenities, "Show Amenities")

        // TESTING: example demo lots for preso
        for (let i = 0; i < lanserCommunity.lots.length; i++) {
            const lot = lanserCommunity.lots[i]

            L.circle([lot.lat, lot.lng], {radius: 5, fillColor: '#FF6900', color: '#FF6900' })
                .addTo(masterplanMap)
                // .bindPopup(`Lot ${lot.lotNo}`)
                .on('click', function() {
                    masterplanControls.showLotDetails(lot.id)
                })
        };

        // TESTING: lat/lng demolots from Christie
        // demoLots.forEach(lot => {
        //     L.circle([lot.lat, lot.lng])
        //         .addTo(masterplanMap)
        //         .bindPopup(`Lot ${lot.lotNo}`)
        // });
    }
}

// misc functions to control the map from outside the module
const masterplanControls = {
    zoomToArea: function(el) {
        const opts = el.dataset
        console.log(`panning to ${opts.lat}, ${opts.lng} at zoom ${opts.zoom}`)
        window.masterplanMap.setView([opts.lat, opts.lng], opts.zoom)
    },
    showLotDetails: function(lotId) {
        console.log('showLotDetails', lotId)
        // dispatch custom window event 'showlotdetails' with the lotId as the detail
        window.dispatchEvent(new CustomEvent('showlotdetails', { detail: lotId }))
    }
}

// const demoLots = [
//     {
//         lotNo:  2382,
//         lat:    -35.08006307,
//         lng:    138.89740501,
//     },
//     {
//         lotNo:  2383,
//         lat:    -35.07994056,
//         lng:    138.89728125,
//     },
//     {
//         lotNo:  2384,
//         lat:    -35.07984592,
//         lng:    138.89718364,
//     },
//     {
//         lotNo:  2385,
//         lat:    -35.07975931,
//         lng:    138.89709598,
//     },
//     {
//         lotNo:  2386,
//         lat:    -35.07968136,
//         lng:    138.89701708,
//     },
//     {
//         lotNo:  2387,
//         lat:    -35.07960341,
//         lng:    138.89693819,
//     },
//     {
//         lotNo:  2388,
//         lat:    -35.07950468,
//         lng:    138.89683826,
//     },
//     {
//         lotNo:  2389,
//         lat:    -35.07937800,
//         lng:    138.89671041,
//     },
//     {
//         lotNo:  2393,
//         lat:    -35.07926785,
//         lng:    138.89660925,
//     },
//     {
//         lotNo:  2394,
//         lat:    -35.07916239,
//         lng:    138.89651191,
//     },
//     {
//         lotNo:  2395,
//         lat:    -35.07899721,
//         lng:    138.89699453,
//     },
//     {
//         lotNo:  2396,
//         lat:    -35.07890668,
//         lng:    138.89714050,
//     },
//     {
//         lotNo:  2397,
//         lat:    -35.07883484,
//         lng:    138.89726329,
//     },
//     {
//         lotNo:  2398,
//         lat:    -35.07876293,
//         lng:    138.89738621,
//     },
//     {
//         lotNo:  2399,
//         lat:    -35.07869759,
//         lng:    138.89749789,
//     },
//     {
//         lotNo:  2756,
//         lat:    -35.08006486,
//         lng:    138.89829363,
//     },
//     {
//         lotNo:  2757,
//         lat:    -35.07993255,
//         lng:    138.89840511,
//     },
//     {
//         lotNo:  2758,
//         lat:    -35.07980860,
//         lng:    138.89851313,
//     },
//     {
//         lotNo:  2759,
//         lat:    -35.07970471,
//         lng:    138.89864471,
//     },
//     {
//         lotNo:  2760,
//         lat:    -35.07962476,
//         lng:    138.89877183,
//     },
//     {
//         lotNo:  2761,
//         lat:    -35.07954348,
//         lng:    138.89889284,
//     },
//     {
//         lotNo:  2762,
//         lat:    -35.07945177,
//         lng:    138.89902799,
//     },
//     {
//         lotNo:  2763,
//         lat:    -35.07933367,
//         lng:    138.89916150,
//     },
//     {
//         lotNo:  2764,
//         lat:    -35.07913887,
//         lng:    138.89898316,
//     },
//     {
//         lotNo:  2765,
//         lat:    -35.07923131,
//         lng:    138.89882926,
//     },
//     {
//         lotNo:  2766,
//         lat:    -35.07931867,
//         lng:    138.89868966,
//     },
//     {
//         lotNo:  2767,
//         lat:    -35.07939644,
//         lng:    138.89856539,
//     },
//     {
//         lotNo:  2768,
//         lat:    -35.07947975,
//         lng:    138.89843225,
//     },
//     {
//         lotNo:  2769,
//         lat:    -35.07958496,
//         lng:    138.89827882,
//     },
//     {
//         lotNo:  2770,
//         lat:    -35.07970055,
//         lng:    138.89813830,
//     },
//     {
//         lotNo:  2771,
//         lat:    -35.07984959,
//         lng:    138.89793588,
//     },
//     {
//         lotNo:  2772,
//         lat:    -35.07954473,
//         lng:    138.89750515,
//     },
//     {
//         lotNo:  2773,
//         lat:    -35.07946870,
//         lng:    138.89763351,
//     },
//     {
//         lotNo:  2774,
//         lat:    -35.07940147,
//         lng:    138.89774349,
//     },
//     {
//         lotNo:  2775,
//         lat:    -35.07933423,
//         lng:    138.89785346,
//     },
//     {
//         lotNo:  2776,
//         lat:    -35.07927372,
//         lng:    138.89795244,
//     },
//     {
//         lotNo:  2777,
//         lat:    -35.07921321,
//         lng:    138.89805142,
//     },
//     {
//         lotNo:  2778,
//         lat:    -35.07915270,
//         lng:    138.89815039,
//     },
//     {
//         lotNo:  2779,
//         lat:    -35.07908547,
//         lng:    138.89826037,
//     },
//     {
//         lotNo:  2780,
//         lat:    -35.07902496,
//         lng:    138.89835935,
//     },
//     {
//         lotNo:  2781,
//         lat:    -35.07896445,
//         lng:    138.89845833,
//     },
//     {
//         lotNo:  2782,
//         lat:    -35.07882998,
//         lng:    138.89867827,
//     },
//     {
//         lotNo:  2783,
//         lat:    -35.07875871,
//         lng:    138.89879485,
//     },
//     {
//         lotNo:  2784,
//         lat:    -35.07860187,
//         lng:    138.89852893,
//     },
//     {
//         lotNo:  2785,
//         lat:    -35.07868024,
//         lng:    138.89837201,
//     },
//     {
//         lotNo:  2786,
//         lat:    -35.07874748,
//         lng:    138.89826204,
//     },
//     {
//         lotNo:  2787,
//         lat:    -35.07886850,
//         lng:    138.89806408,
//     },
//     {
//         lotNo:  2788,
//         lat:    -35.08020548,
//         lng:    138.89756902,
//     },
//     {
//         lotNo:  2789,
//         lat:    -35.08029988,
//         lng:    138.89774340,
//     },
//     {
//         lotNo:  2790,
//         lat:    -35.08036441,
//         lng:    138.89790146,
//     },
//     {
//         lotNo:  2791,
//         lat:    -35.08042196,
//         lng:    138.89805084,
//     },
//     {
//         lotNo:  2792,
//         lat:    -35.08048779,
//         lng:    138.89823266,
//     },
//     {
//         lotNo:  2800,
//         lat:    -35.07863875,
//         lng:    138.89759846,
//     },
//     {
//         lotNo:  2801,
//         lat:    -35.07857997,
//         lng:    138.89769892,
//     },
//     {
//         lotNo:  2802,
//         lat:    -35.07851853,
//         lng:    138.89780393,
//     },
//     {
//         lotNo:  2810,
//         lat:    -35.07843821,
//         lng:    138.89794400,
//     },
//     {
//         lotNo:  3205,
//         lat:    -35.07880799,
//         lng:    138.89816306,
//     },
//     {
//         lotNo:  3206,
//         lat:    -35.07889721,
//         lng:    138.89856830,
//     },
//     {
//         lotNo:  3207,
//         lat:    -35.07893573,
//         lng:    138.89795410,
//     },
//     {
//         lotNo:  3208,
//         lat:    -35.07899624,
//         lng:    138.89785513,
//     },
//     {
//         lotNo:  3210,
//         lat:    -35.07905675,
//         lng:    138.89775615,
//     },
//     {
//         lotNo:  3211,
//         lat:    -35.07911726,
//         lng:    138.89765717,
//     },
//     {
//         lotNo:  3212,
//         lat:    -35.07918449,
//         lng:    138.89754720,
//     },
//     {
//         lotNo:  3213,
//         lat:    -35.07925173,
//         lng:    138.89743722,
//     },
//     {
//         lotNo:  3214,
//         lat:    -35.07933262,
//         lng:    138.89730309,
//     },
// ]

export default { initMaps, masterplanControls }
