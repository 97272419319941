import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var cards = new Swiper('.cards-carousel', {
  autoplay: {
    enabled: true,
    delay: 1000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var infoCardsImage = new Swiper('[data-carousel="infoCardsImage"]', {
  direction: 'vertical',
  slidesPerView: 1,
  autoplay: {
    enabled: true,
    delay: 3000,
  },
  speed: 1000,
});

var infoCards = new Swiper('[data-carousel="infoCards"]', {
  slidesPerView: 1,
  autoplay: {
    enabled: true,
    delay: 3000,
  },
  speed: 1000,
  thumbs: {
    swiper: infoCardsImage,
  },
});

var heroInfo = new Swiper('[data-carousel="heroInfo"]', {
  slidesPerView: 1,
  autoplay: {
    enabled: true,
    delay: 3000,
  },
  speed: 1000,
});

var latestNews = new Swiper('[data-carousel="latestNews"]', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.news-next',
    prevEl: '.news-prev',
  },
  breakpoints: {
    1280: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 1,
    },
  },
});

var similarAllotments = new Swiper('[data-carousel="similarAllotments"]', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.allotments-next',
    prevEl: '.allotments-prev',
  },
  breakpoints: {
    1280: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 40,
    },
  },
});

var imageGalleryThumb = new Swiper('[data-carousel="imageGalleryThumb"]', {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,
});

var imageGallery = new Swiper('[data-carousel="imageGallery"]', {
  spaceBetween: 10,
  navigation: {
    nextEl: '.imageGallery-next',
    prevEl: '.imageGallery-prev',
  },
  thumbs: {
    swiper: imageGalleryThumb,
  },
});